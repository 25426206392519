import Vue from 'vue'
import VueRouter from 'vue-router'
import { useApi } from './api'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('@/views/Dashboard.vue')
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/settings/Users.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/settings/Settings.vue')
  },
  {
    path: '/sales-prices',
    name: 'sales-prices',
    component: () => import('@/views/settings/SalesPrices.vue')
  },
  {
    path: '/import-old',
    name: 'import-old',
    component: () => import('@/views/ImportOld.vue')
  },
  {
    path: '/import',
    name: 'import',
    component: () => import('@/views/Import.vue')
  },
  {
    path: '/products/variants',
    name: 'variants',
    component: () => import('@/views/ProductVariants.vue')
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('@/views/Products.vue')
  },
  {
    path: '/products/:id',
    name: 'product',
    component: () => import('@/views/Product.vue')
  },
  {
    path: '/products/variants/:id',
    name: 'variant',
    component: () => import('@/views/ProductVariant.vue')
  },
  {
    path: '/suppliers',
    name: 'suppliers',
    component: () => import('@/views/Suppliers.vue')
  },
  {
    path: '/sizes',
    name: 'sizes',
    component: () => import('@/views/Sizes.vue')
  },
  {
    path: '/sizes/:id',
    name: 'size',
    component: () => import('@/views/Size.vue')
  },
  {
    path: '/colors',
    name: 'colors',
    component: () => import('@/views/Colors.vue')
  },
  {
    path: '/colors/:id',
    name: 'color',
    component: () => import('@/views/Color.vue')
  },
  {
    path: '/categories',
    name: 'categories',
    component: () => import('@/views/Categories.vue')
  },
  {
    path: '/brands',
    name: 'brands',
    component: () => import('@/views/Brands.vue')
  },
  {
    path: '/attributes',
    name: 'attributes',
    component: () => import('@/views/Attributes.vue')
  },
  {
    path: '/attributes/:id',
    name: 'attribute',
    component: () => import('@/views/Attribute.vue')
  },
  {
    path: '/export',
    name: 'export',
    component: () => import('@/views/Export.vue')
  },
  {
    path: '/jobs',
    name: 'jobs',
    component: () => import('@/views/Jobs.vue')
  },
  {
    path: '/websites',
    name: 'websites',
    component: () => import('@/views/Websites.vue')
  },
  {
    path: '/website-profiles',
    name: 'website-profiles',
    component: () => import('@/views/WebsiteProfiles.vue')
  },
  {
    path: '/website-profiles/:id',
    name: 'website-profile',
    component: () => import('@/views/WebsiteProfile.vue')
  },
  {
    path: '/print-textiles',
    name: 'print-textiles',
    component: () => import('@/views/PrintTextiles.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const { useRoute, useRouter } = useApi(router) 

export default router
export { useRoute, useRouter }