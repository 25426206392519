/* eslint-disable */
import { ref, computed } from 'vue'
import { transform, isEqual, isArray, cloneDeep } from 'lodash'

function isObject(x) {
  return typeof x === 'object' && x !== null
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}


export default class Model {

  constructor(client, path, options = {}) {

    this._client = client
    this._path = path // 'users/:id'
    this._options = options

    this.attributes = ref({})
    this.oldAttributes = ref({})
    this.errors = ref({})
    
    this.isNewRecord = computed(() => !Object.keys(this.oldAttributes.value).length)

    this.isFinding = ref(false)
    this.isCreating = ref(false)
    this.isUpdating = ref(false)
    this.isSaving = ref(false)
    this.isDeleting = ref(false)
    this.isPosting = ref(false)
    this.isPutting = ref(false)
    this.isBusy = computed(() => this.isFinding.value || this.isCreating.value || this.isUpdating.value || this.isSaving.value || this.isDeleting.value || this.isPosting.value || this.isPutting.value)

    this._afterFind = this._options.afterFind || (val => val)
    this._beforeSave = this._options.beforeSave || (val => val)
    this._afterSave = this._options.afterSave || (val => val)
    this._afterValidationError = this._options.afterValidationError || (val => val)

    this._delay = this._options.delay || 10
    this.initialAttributes = this._options.attributes || {}

    this.attributes.value = Object.assign({}, this.initialAttributes)
  }

  _log() {
    this._options.debug && console.log(...arguments)
  }

  clear() {
    this._log('clear')

    this.attributes.value = Object.assign({}, this.initialAttributes)
    this.oldAttributes.value = {}
    this.errors.value = {}

    return this
  }

  clearErrors() {
    this._log('clear errors')

    this.errors.value = {}

    return this
  }

  _getPath(attributes = {}) {
    return this._path.replace(/:([\w]+)/g, name => {
      return Object.keys(attributes).includes(name.substr(1)) ? attributes[name.substr(1)] : ''
    }).replace(/[/,]+$/, '')
  }

  _setErrors(errors) {
    const self = this
    const _errors = {}

    if (Array.isArray(errors)) {
      errors.forEach(element => {
        if (element.field && element.message) {
          if (!Object.keys(self.errors.value).includes(element.field)) {
            _errors[element.field] = []
          }
          _errors[element.field].push(element.message)
        }
      })
    }

    this.errors.value = _errors
  }

  find(id) {
    const path = this._getPath(isObject(id) ? id : { id })
    const params = this._options.params || {}
    const self = this

    this._log('find', path)
    this.isFinding.value = true

    return this._client.get(path, { params })
      .then(response => {
        this.clear()
        this.populate(response.data)
        this._afterFind(self)
        return Promise.resolve(self)
      })
      .catch(error => {
        this.clear()
        return Promise.reject(error)
      })
      .finally(() => {
        this.isFinding.value = false
      })
  }

  create(names = null) {
    const self = this
    this._setErrors(null)
    return sleep(this._delay).then(() => {
      this._beforeSave(self)

      const path = this._getPath() // don't use placeholders with POST
      const attributes = this._getAttributes(names)
      const params = this._options.params || {}

      this._log('create', path, attributes)
      this.isCreating.value = true

      return this._client.post(path, attributes, { params })
        .then(response => {
          this.populate(response.data)
          this._afterSave(self)
          return Promise.resolve(self)
        })
        .catch(error => {
          if (error && error.response && error.response.status === 422) {
            this._setErrors(error.response.data)
            this._afterValidationError(error)
          }
          return Promise.reject(error)
        })
        .finally(() => {
          this.isCreating.value = false
        })
    })
  }

  post(names = null) {
    const self = this
    this._setErrors(null)
    return sleep(this._delay).then(() => {

      const path = this._getPath(this.attributes.value)
      const attributes = this._getAttributes(names)
      const params = this._options.params || {}

      this._log('post', path, attributes)
      this.isPosting.value = true

      return this._client.post(path, attributes, { params })
        .then(response => {
          return Promise.resolve(response.data)
        })
        .catch(error => {
          if (error && error.response && error.response.status === 422) {
            this._setErrors(error.response.data)
            this._afterValidationError(error)
          }
          return Promise.reject(error)
        })
        .finally(() => {
          this.isPosting.value = false
        })
    })
  }


  update(names = null, forceUpdate = false) {
    const self = this
    this._setErrors(null)
    return sleep(this._delay).then(() => {
      self._beforeSave(self)

      const path = this._getPath(this.oldAttributes.value)
      const attributes = forceUpdate ? this._getAttributes(names) : this._getDirtyAttributes(names)
      const params = this._options.params || {}

      this._log('update', path, attributes)
      this.isUpdating.value = true

      return this._client.put(path, attributes, { params })
        .then(response => {
          this.populate(response.data, Object.keys(attributes))
          this._afterSave(self)
          return Promise.resolve(self)
        })
        .catch(error => {
          if (error && error.response && error.response.status === 422) {
            this._setErrors(error.response.data)
            this._afterValidationError(error)
          }
          return Promise.reject(error)
        })
        .finally(() => {
          this.isUpdating.value = false
        })

    })
  }

  save(names = null, forceUpdate = false) {
    this._log('save')
    this.isSaving.value = true

    return (Object.keys(this.oldAttributes.value).length ? this.update(names, forceUpdate) : this.create(names)).finally(() => {
      this.isSaving.value = false
    })
  }

  delete(id) {
    const path = id ? this._getPath(isObject(id) ? id : { id }) : this._getPath(this.oldAttributes.value)
    const params = this._options.params || {}
    const self = this

    this._log('delete', path)
    this.isDeleting.value = true

    return this._client.delete(path, { params })
      .then(() => Promise.resolve(self))
      .catch(error => Promise.reject(error))
      .finally(() => {
        this.isDeleting.value = false
      })
  }

  refresh() {
    this._log('refresh')
    return this.find(this.oldAttributes.value)
  }

  reset(names = null) {

    names = Array.isArray(names)
      ? Object.keys(this.attributes.value).filter(name => names.includes(name))
      : Object.keys(this.attributes.value)

    this._log('reset', names)

    const oldNames = Object.keys(this.oldAttributes.value)
    const self = this
    const _attributes = Object.assign({}, this.attributes.value)

    names.forEach(name => {
      _attributes[name] = oldNames.includes(name) ? cloneDeep(self.oldAttributes.value[name]) : null
    })

    this.attributes.value = _attributes

    this._setErrors([])
    return this
  }

  /**
   * Returns the deep difference between two objects
   * @param {*} object 
   * @param {*} base 
   * @returns array with difference
   */
  _difference(object, base) {
    const self = this
    return transform(object, (result, value, key) => {
      if (!isEqual(value, base[key])) {
        result[key] = isObject(value) && isObject(base[key]) && !isArray(value) ? self._difference(value, base[key]) : value
      }
    })
  }

  /**
   * Returns the attribute values that have been modified since they are loaded or saved most recently.
   * @param {string[]|null} names the names of the attributes whose values may be returned if they are
   * changed recently. If null or true, [[attributes()]] will be used.
   * @return {array} the changed attribute values (name-value pairs)
   */  
   _getDirtyAttributes(names = null) {

    names = Array.isArray(names)
      ? Object.keys(this.attributes.value).filter(name => names.includes(name))
      : Object.keys(this.attributes.value)

    const dirtyAttributes = this._difference(this.attributes.value, this.oldAttributes.value)
    const dirtyNames = Object.keys(dirtyAttributes)
    const attributes = {}

    names.forEach(name => {
      if (dirtyNames.includes(name)) {
        attributes[name] = cloneDeep(dirtyAttributes[name])
      }
    })

    return attributes
  }

  /**
   * Returns attribute values.
   * @param array names list of attributes whose value needs to be returned.
   * Defaults to null, meaning all attributes listed in [[attributes()]] will be returned.
   * If it is an array, only the attributes in the array will be returned.
   * @param array except list of attributes whose value should NOT be returned.
   * @return array attribute values (name => value).
   */
   _getAttributes(names = null, except = []) {

    names = Array.isArray(names)
      ? Object.keys(this.attributes.value).filter(name => names.includes(name) && !except.includes(name))
      : Object.keys(this.attributes.value).filter(name => !except.includes(name))

    const attributes = {}
    const self = this

    names.forEach(name => {
      attributes[name] = cloneDeep(self.attributes.value[name])
    })

    return attributes
  }


  /**
   * Populates current model with given attributes
   * @param {*} attributes 
   * @param {array|null} names the attribute names that are allowed. null means all
   */

   populate(attributes, names = null) {

    if (!isObject(attributes)) {
      return this
    }

    const dirtyAttributes = this._getDirtyAttributes()
    const dirtyNames = Object.keys(dirtyAttributes)

    names = Array.isArray(names)
      ? Object.keys(attributes).filter(name => names.includes(name))
      : Object.keys(attributes)

    const populatedAttributes = {}
    const _attributes = Object.assign({}, this.attributes.value)
    const _oldAttributes = Object.assign({}, this.oldAttributes.value)

    Object.keys(attributes).forEach(name => {
      if (names.includes(name) || !dirtyNames.includes(name)) {
        _attributes[name] = cloneDeep(attributes[name])
        populatedAttributes[name] = attributes[name]
      }
      _oldAttributes[name] = cloneDeep(attributes[name])
    })

    this.attributes.value = _attributes
    this.oldAttributes.value = _oldAttributes

    this._log('populate', populatedAttributes)

    return this
  }


  setAttribute(name, value) {
    this.attributes.value = Object.assign({}, this.attributes.value, { [name]: value })
    return this
  }

  setAttributes(attributes) {
    const self = this
    Object.keys(attributes).forEach(name => {
      self.setAttribute(name, attributes[name])
    })
    return this
  }

  getAttribute(name, defaultValue = null) {
    return Object.keys(this.attributes.value).includes(name) ? this.attributes.value[name] : defaultValue
  }

  getAttributes() {
    return this.attributes.value
  }

  getOldAttribute(name, defaultValue = null) {
    return Object.keys(this.oldAttributes.value).includes(name) ? this.oldAttributes.value[name] : defaultValue
  }

  getOldAttributes() {
    return this.oldAttributes.value
  }

  setJsonAttribute(name, value) {
    this.setAttribute(name, JSON.stringify(value))
    return this
  }

  getJsonAttribute(name, defaultValue = null) {
    try {
      return JSON.parse(this.getAttribute(name))
    } catch(err) {
      console.log(err)
      return defaultValue
    }
  }

}