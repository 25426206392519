<template>
 <v-app
  dark
    :style="{ 'background-color': $vuetify.theme.currentTheme.background }"
  >
    <v-navigation-drawer
      app
      v-if="isLoggedIn"
      v-model="drawer"
      clipped
    ><Menu v-model="drawer" :items="menuItems" /></v-navigation-drawer>

    <v-app-bar
      app
      v-if="isLoggedIn"
      flat
      color="tertairy"
      clipped-left
      dark
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <v-img
        src="brezo-logo-new.svg"
        contain
        max-width="250"
        max-height="40"
        class="app-logo"
      ></v-img>

      <v-spacer></v-spacer>

      <v-btn
        icon
        @click="logout()"
        color="secondary"
        height="40"
      ><v-icon
      >far fa-sign-out</v-icon></v-btn>

      <div
        class="mx-5"
      >{{ identity.first_name }}</div>

      <v-avatar
        color="secondary"
        height="40"
        class="ml-2"
      >
        <v-icon
          color="white"
        >fas fa-user</v-icon>
      </v-avatar>

    </v-app-bar>

    <v-main
      v-if="isLoggedIn"
    >
      <v-container
        fluid
      >
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-main
      v-else
    ><Login /></v-main>

    <div
      @click="overlayClick"
    >
      <v-overlay
        :opacity="0.5"
        :value="loader.state.isActive"
        z-index="999999"
      >
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>    
    </div>

  </v-app>
</template>

<script>
import { provide, inject, ref, computed } from 'vue'
import Login from '@/views/Login'
import Menu from '@/views/Menu'

export default {
  name: 'App',

  components: {
    Login,
    Menu,
  },

  setup() {

    const drawer = ref(true)
    const api = inject('api')
    const { isLoggedIn, logout, identity } = api.user

    const loader = api.utils.createLoader()
    provide('loader', loader)

    function overlayClick() {
      loader.clear()
    }

    const counterModel = api.model('users/counter')
    counterModel.find()
    provide('counterModel', counterModel)
    const { attributes: counter } = counterModel

    setInterval(() => {
      counterModel.refresh()
    }, 30000)

    const menuItems = computed(() => ([
      {
        title: 'Dashboard',
        icon: 'fas fa-tachometer-fast',
        to: { name: 'dashboard' },
      },
      {
        title: 'Import',
        icon: 'fas fa-file-import',
        to: { name: 'import' },
        hidden: !(api.user.identity.value.permissions || []).includes('product-variant-supplier.import'),
      },
      // {
      //   title: 'Import (old)',
      //   icon: 'fas fa-file-import',
      //   to: { name: 'import-old' },
      //   hidden: !(api.user.identity.value.permissions || []).includes('product-variant-supplier.import'),
      // },
      {
        title: 'Products',
        icon: 'fas fa-tshirt',
        to: { name: 'products' },
      },
      {
        title: 'Variants',
        icon: 'fas fa-sitemap',
        to: { name: 'variants' },
      },
      {
        title: 'Suppliers',
        icon: 'fas fa-box',
        to: { name: 'suppliers' },
      },
      {
        title: 'Sizes',
        icon: 'fas fa-ruler-triangle',
        to: { name: 'sizes' },
      },
      {
        title: 'Colors',
        icon: 'fas fa-tint',
        to: { name: 'colors' },
      },
      {
        title: 'Categories',
        icon: 'fas fa-layer-group',
        to: { name: 'categories' },
      },
      {
        title: 'Brands',
        icon: 'fas fa-copyright',
        to: { name: 'brands' },
      },
      {
        title: 'Attributes',
        icon: 'fas fa-puzzle-piece',
        to: { name: 'attributes' },
      },
      {
        title: 'Export',
        icon: 'fas fa-file-export',
        to: { name: 'export' },
      },
      {
        title: 'Jobs',
        icon: 'fas fa-rocket',
        to: { name: 'jobs' },
        badge: counter.value.failed_jobs ? {
          title: counter.value.failed_jobs,
          color: 'red',
        } : null,
      },
      {
        title: 'Websites',
        icon: 'fas fa-browser',
        to: { name: 'websites' },
      },
      {
        title: 'Website Profiles',
        icon: 'fas fa-browser',
        to: { name: 'website-profiles' },
      },
      {
        title: 'Print Textiles',
        icon: 'fas fa-print',
        to: { name: 'print-textiles' },
      },
      {
        title: 'Settings',
        icon: 'fas fa-cog',

        items: [
          {
            title: 'Users',
            icon: null,
            to: { name: 'users' },
          },
          {
            title: 'Settings',
            icon: null,
            to: { name: 'settings' },
            hidden: !(api.user.identity.value.permissions || []).includes('setting.update'),
          },
          {
            title: 'Sales Prices',
            icon: null,
            to: { name: 'sales-prices' },
          }
        ],
      }
    ]))


    return {
      identity,
      isLoggedIn,
      drawer,
      logout,
      loader,
      overlayClick,
      menuItems,
    }

  }
};
</script>


<style scoped>

.app-logo {
  margin-left: 128px !important;
  margin-top: -28px !important;
}



</style>
